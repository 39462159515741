import React, { useRef, forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import * as Icon from 'react-feather';
import { Card, CardTitle, CardBody, Label, Row, Col, FormGroup, Badge, Collapse } from 'reactstrap';
import UppyFileUploader from '../../layouts/uppy/uppy';
import { ProcessingModal } from '../modals/CustomModals';
import TooltipItem from './Tooltip';

const TenderDocumentsFormModal = forwardRef((props, ref) => {
  const initialValues = {
    TenderNotes: props?.existingNotes ?? '',
    TenderDocs: props?.existingFiles ?? '',
    TenderLocation: props.existingTenderLocation ?? '',
  };

  const validationSchema = Yup.object({
    TenderNotes: Yup.string().required('Tender Notes is required'),
    TenderLocation: Yup.string().required('Tender Location is required'),
  });

  const tenderDocumentationRef = useRef();
  const formikRef = useRef();
  const processingModalRef = useRef();

  const [collapse, setCollapse] = useState(true);

  useImperativeHandle(ref, () => ({
    async validateForm() {
      const response = await formikRef.current.validateForm();
      return response;
    },
    getInputValues() {
      return formikRef.current.values;
    },
  }));

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formikRef}
      onSubmit={() => {
        /* Do nothing. Everything is handled in the handleSubmit function */
      }}
    >
      {(formik) => (
        <Card>
          <CardTitle
            tag="h5"
            className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
            onClick={toggle.bind(null)}
            style={{ zIndex: 1 }}
          >
            <span style={{ float: 'left' }}>{props.title}</span>
            <span style={{ float: 'left' }} className="text-danger">
              &nbsp;{props.warningMessage}
            </span>
            <Badge
              color="primary"
              className={`ms-3 ${!props.showUpload ? 'd-none' : ''}`}
              pill
              style={{ float: 'right', zIndex: 999 }}
              onClick={(e) => {
                e.stopPropagation();
                props.uploadCallback();
              }}
            >
              Upload <Icon.FilePlus size={20} />
            </Badge>
            {collapse ? (
              <Icon.Minus size={30} style={{ float: 'right' }} />
            ) : (
              <Icon.Plus size={30} style={{ float: 'right' }} />
            )}
          </CardTitle>
          <Collapse isOpen={collapse}>
            <CardBody className="card-rounded-bottom">
              <Row>
                <Col md="12" style={{ textAlign: 'left' }}>
                  <FormGroup>
                    <Label htmlFor="TenderLocation">Location of the Tender Pack</Label>
                    <Field name="TenderLocation" className="form-control" maxLength="100" />
                    {formik.errors.TenderLocation && (
                      <div
                        style={{ display: 'block', textAlign: 'left' }}
                        className="invalid-feedback"
                      >
                        {formik.errors.TenderLocation}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="TenderDocumentation">Applicable Tender Document</Label>
                    <Icon.HelpCircle
                      id="toolTip-tenderDocuments"
                      size={20}
                      style={{ color: 'blue', marginLeft: '10px' }}
                    />
                    <TooltipItem target="toolTip-tenderDocuments" placement="top">
                      <div>
                        <p style={{ textAlign: 'left' }}>More Info in Sharepoint</p>
                      </div>
                    </TooltipItem>
                    <UppyFileUploader
                      id="TenderDocumentation"
                      uploadUrl={props.fileUploadUrl}
                      downloadUrl={props.fileDownloadUrl}
                      existingFiles={formik.values.TenderDocs}
                      description="Tender Documentation"
                      ref={tenderDocumentationRef}
                      disabled={props.disabled}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Label htmlFor="TenderNotes">Notes</Label>
                  <Field
                    className="form-control"
                    component="textarea"
                    name="TenderNotes"
                    placeholder=""
                    rows="5"
                    style={{ resize: 'none' }}
                  />
                  {formik.errors.TenderNotes && (
                    <div
                      style={{ display: 'block', textAlign: 'left' }}
                      className="invalid-feedback"
                    >
                      {formik.errors.TenderNotes}
                    </div>
                  )}
                </Col>
              </Row>
              <ProcessingModal ref={processingModalRef} />
            </CardBody>
          </Collapse>
        </Card>
      )}
    </Formik>
  );
});

TenderDocumentsFormModal.propTypes = {
  fileUploadUrl: PropTypes.string,
  fileDownloadUrl: PropTypes.string,
  existingFiles: PropTypes.array,
  existingNotes: PropTypes.string,
  existingTenderLocation: PropTypes.string,
  title: PropTypes.string,
  showUpload: PropTypes.bool,
  uploadCallback: PropTypes.func,
  disabled: PropTypes.bool,
  warningMessage: PropTypes.string,
};

export default TenderDocumentsFormModal;
