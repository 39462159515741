import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label, FormText, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import * as Icon from 'react-feather';
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete';
import TooltipItem from './Tooltip';

const SiteContactDetails = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(true);
  const [initialValues] = useState({
    [`SiteContactName_${props.index}`]: props.values
      ? props.values[`SiteContactName_${props.index}`]
      : '',
    [`SiteContactRole_${props.index}`]: props.values
      ? props.values[`SiteContactRole_${props.index}`]
      : '',
    [`SiteMobileNumberCountry_${props.index}`]: props.values
      ? props.values[`SiteMobileNumberCountry_${props.index}`]
      : '',
    [`SiteMobileNumber_${props.index}`]: props.values
      ? props.values[`SiteMobileNumber_${props.index}`]
      : '',
    [`SiteName_${props.index}`]: props.values ? props.values[`SiteName_${props.index}`] : '',
    [`SiteEmail_${props.index}`]: props.values ? props.values[`SiteEmail_${props.index}`] : '',
    [`SiteAddress_${props.index}`]: props.values ? props.values[`SiteAddress_${props.index}`] : '',
    [`State_${props.index}`]: props.values ? props.values[`State_${props.index}`] : '',
    visible: true,
  });

  const [validationSchema] = useState(
    Yup.object({
      [`SiteContactName_${props.index}`]: Yup.string().required(
        `Site Contact Name ${props.index === 1 ? '' : props.index} is required`,
      ),
      [`SiteContactRole_${props.index}`]: Yup.string().required(
        `Site Contact Role ${props.index === 1 ? '' : props.index} at Company is required`,
      ),
      [`SiteMobileNumber_${props.index}`]: Yup.string().required(
        `Site Contact Mobile Number ${props.index === 1 ? '' : props.index} is required`,
      ),
      [`SiteEmail_${props.index}`]: Yup.string().required(
        `Site Contact Email Address ${props.index === 1 ? '' : props.index} is required`,
      ),
    }),
  );

  const formikRef = useRef();

  const handleSameAsClientDetails = (e, formik) => {
    if (e.target.checked) {
      formik.setFieldValue(
        `SiteContactName_${props.index}`,
        document.getElementById('MainContact')?.value
          ? document.getElementById('MainContact')?.value
          : document.getElementById('Name_1')?.value,
      );
      formik.setFieldValue(
        `SiteContactRole_${props.index}`,
        document.getElementById('MainContactRole')?.value
          ? document.getElementById('MainContactRole')?.value
          : document.getElementById('Role_1').value,
      );
      formik.setFieldValue(
        `SiteEmail_${props.index}`,
        document.getElementById('ClientEmail')?.value
          ? document.getElementById('ClientEmail')?.value
          : document.getElementById('Email_1').value,
      );
      formik.setFieldValue(
        `SiteMobileNumberCountry_${props.index}`,
        document.getElementById('ClientMobileNumberCountry')?.value
          ? document.getElementById('ClientMobileNumberCountry')?.value
          : document.getElementById('MobileNumberCountry_1').value,
      );
      formik.setFieldValue(
        `SiteMobileNumber_${props.index}`,
        document.getElementById('ClientMobileNumber')?.value
          ? document.getElementById('ClientMobileNumber')?.value
          : document.getElementById('MobileNumber_1').value,
      );
    }
  };

  const handlePlaceChange = (place, e) => {
    formikRef.current.values[e] = place?.state ?? '';
  };

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
      const validationErrors = await formikRef.current.validateForm();
      const stateKey = `State_${props.index}`;

      if (props.multiSite) {
        // Manual check for Site Address
        if (
          !document.getElementById(`SiteAddress_${props.index}`).value ||
          !formikRef.current.values[stateKey]
        ) {
          validationErrors[`SiteAddress_${props.index}`] = `Site Address ${
            props.index === 1 ? '' : props.index
          } is required. Please select the address from the dropdown'`;
        } else {
          delete validationErrors?.[`SiteAddress_${props.index}`];
        }

        if (!formikRef.current.values[`SiteName_${props.index}`]) {
          validationErrors[`SiteName_${props.index}`] = `Site Name ${
            props.index === 1 ? '' : props.index
          } is required.'`;
        } else {
          delete validationErrors[`SiteName_${props.index}`];
        }
      }

      return validationErrors;
    },
    getInputValues: () => {
      const temp = formikRef.current?.values;
      if (props.multiSite) {
        // Override since Site Address is not using Formik
        temp[`SiteAddress_${props.index}`] = document.getElementById(
          `SiteAddress_${props.index}`,
        ).value;
      }

      temp[`SiteMobileNumberCountry_${props.index}`] = document.getElementById(
        `SiteMobileNumberCountry_${props.index}`,
      ).value;

      return temp;
    },
    hideEverything: () => {
      formikRef.current.values.visible = false;
      setVisible(false);
    },
    isVisible: () => {
      return formikRef.current.values?.visible;
    },
  }));

  return (
    <div>
      {visible ? (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            // Do nothing. Everything is handled in the handleSubmit function
          }}
        >
          {(formik) => {
            return (
              <Row>
                <Col md="12">
                  <Card>
                    <CardTitle
                      tag="h5"
                      className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                    >
                      <span style={{ float: 'left' }}>
                        Site Contact Details {props.index === 1 ? '' : props.index}
                      </span>
                      <Icon.HelpCircle
                        id="toolTip-contactDetails"
                        size={20}
                        style={{ color: 'blue', float: 'left', marginLeft: '10px' }}
                      />
                      <TooltipItem target="toolTip-contactDetails" placement="top">
                        <div>
                          <p style={{ textAlign: 'left' }}>
                            Alongside main client contact, please include contact details of the
                            Facilities Manager, or contact who has access to the site including keys
                            and ideally basic knowledge of the services
                          </p>
                        </div>
                      </TooltipItem>
                      {props.showAddSiteButton ? (
                        <Badge
                          color="primary"
                          pill
                          style={{ float: 'right', zIndex: 999, cursor: 'pointer' }}
                          onClick={props.addSiteCallback}
                        >
                          Add Site <Icon.FilePlus size={20} />
                        </Badge>
                      ) : (
                        ''
                      )}
                    </CardTitle>
                    <CardBody className="card-rounded-bottom">
                      {props.showSameAsClient ? (
                        <>
                          <Row>
                            <Col md="6">
                              <FormGroup check style={{ textAlign: 'left' }}>
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  id="SameAsClientDetails"
                                  name="SameAsClientDetails"
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    handleSameAsClientDetails(e, formik);
                                  }}
                                />
                                <FormText color="secondary">Same as Client Details</FormText>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ) : (
                        ''
                      )}
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`SiteContactName_${props.index}`}>
                              Site Contact Name
                            </Label>
                            <Field
                              name={`SiteContactName_${props.index}`}
                              id={`SiteContactName_${props.index}`}
                              className="form-control"
                              value={formik.values[`SiteContactName_${props.index}`]}
                            />
                            {formik.errors[`SiteContactName_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`SiteContactName_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`SiteContactRole_${props.index}`}>
                              Site Contact Role at Company
                            </Label>
                            <Field
                              name={`SiteContactRole_${props.index}`}
                              id={`SiteContactRole_${props.index}`}
                              className="form-control"
                              value={formik.values[`SiteContactRole_${props.index}`]}
                            />
                            {formik.errors[`SiteContactRole_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`SiteContactRole_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`SiteMobileNumberCountry_${props.index}`}>
                              Site Contact Mobile Number
                            </Label>
                            <div style={{ display: 'flex' }}>
                              <Field
                                as="select"
                                className="form-select"
                                id={`SiteMobileNumberCountry_${props.index}`}
                                name={`SiteMobileNumberCountry_${props.index}`}
                                onChange={formik.handleChange}
                                style={{ width: '100px', marginRight: '10px' }}
                              >
                                <option value="+61">+61</option>
                                <option value="+64">+64</option>
                              </Field>
                              <Field
                                name={`SiteMobileNumber_${props.index}`}
                                id={`SiteMobileNumber_${props.index}`}
                                className="form-control"
                                value={formik.values[`SiteMobileNumber_${props.index}`]}
                                style={{ width: '200px', marginRight: 0 }}
                              />
                            </div>
                            {formik.errors[`SiteMobileNumber_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`SiteMobileNumber_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor={`SiteEmail_${props.index}`}>
                              Site Contact Email Address
                            </Label>
                            <Field
                              name={`SiteEmail_${props.index}`}
                              id={`SiteEmail_${props.index}`}
                              className="form-control"
                              value={formik.values[`SiteEmail_${props.index}`]}
                            />
                            {formik.errors[`SiteEmail_${props.index}`] ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors[`SiteEmail_${props.index}`]}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {props.multiSite ? (
                          <>
                            <Col md="6" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor={`SiteName_${props.index}`}>Site Name</Label>
                                <Field
                                  name={`SiteName_${props.index}`}
                                  id={`SiteEmail_${props.index}`}
                                  className="form-control"
                                  value={formik.values[`SiteName_${props.index}`]}
                                />
                                {formik.errors[`SiteName_${props.index}`] && (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors[`SiteName_${props.index}`]}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor={`SiteAddress_${props.index}`}>Site Address</Label>
                                {/* <Field name="SiteAddress" className='form-control' value={formik.values.SiteAddress} /> */}
                                <GooglePlacesAutocomplete
                                  id={`SiteAddress_${props.index}`}
                                  name={`SiteAddress_${props.index}`}
                                  initialValue={formik.values[`SiteAddress_${props.index}`]}
                                  callBack={(e) => {
                                    handlePlaceChange(e, `State_${props.index}`);
                                  }}
                                />
                                {formik.errors[`SiteAddress_${props.index}`] ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors[`SiteAddress_${props.index}`]}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <Field
                                  name={`State_${props.index}`}
                                  id={`State_${props.index}`}
                                  className="form-control d-none"
                                  value={formik.values[`State_${props.index}`]}
                                />
                              </FormGroup>
                            </Col>
                          </>
                        ) : (
                          ''
                        )}
                      </Row>
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          {props.showRemoveButton ? (
                            <Badge
                              color="danger"
                              pill
                              style={{ zIndex: 999, cursor: 'pointer' }}
                              onClick={props.removeButtonCallback}
                            >
                              Remove <Icon.Minus size={20} />
                            </Badge>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            );
          }}
        </Formik>
      ) : (
        ''
      )}
    </div>
  );
});

SiteContactDetails.propTypes = {
  showAddSiteButton: PropTypes.bool,
  addSiteCallback: PropTypes.func,
  index: PropTypes.number,
  values: PropTypes.object,
  showRemoveButton: PropTypes.bool,
  removeButtonCallback: PropTypes.func,
  multiSite: PropTypes.bool,
  showSameAsClient: PropTypes.bool,
};

export default SiteContactDetails;
