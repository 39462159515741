import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import jQuery from 'jquery';
import * as Icon from 'react-feather';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { postAsync, getAsync, putAsync } from '../../../functions/my-api';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import htmlToPdf from '../../../functions/my-pdf';
import {
  ProcessingModal,
  GenericMessageModal,
  ErrorModal,
  RequiredFieldsWarningModal,
  SmallModal,
  ConfirmationModal,
} from '../../modals/CustomModals';
import SiteContactDetails from '../../widgets/SiteContactDetails';
import ContactDetails from '../../widgets/ContactDetails';
import GooglePlacesAutocomplete from '../../widgets/GooglePlacesAutocomplete';
import TooltipItem from '../../widgets/Tooltip';

// The UtilityClass is a hack since siteContactDetailsRefList doesnt really reflect the total number of dynamic refs.
import UtilityClass, { generateRandomString } from '../../../functions/common';
import PrivacyPolicy from '../../widgets/PrivacyPolicy';
import Milestone from '../../widgets/Milestone';
import TenderDocumentation from '../../widgets/TenderDocumentation';
import TenderDetails from '../../widgets/TenderDetails';

require('moment/locale/en-gb');

const IswForm = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const draftValidationSchema = Yup.object({
    ClientCompany: Yup.string().required('Client is required'),
  });

  const notDraftValidationSchema = Yup.object({
    _4GCommunications: Yup.string().required(
      'Do you have strong 4G network coverage in the proposed location of the chargers is required',
    ),
    AdditionalStations: Yup.string().required(
      'Are you interested in adding capacity for additional future EV charging stations as part of this installation? This assists JET Charge in understanding the potential need for load management system or increased switchboard and electrical cable rated capacity. Note the inclusion of future-capacity will increase project cost is required',
    ),
    Bollards: Yup.string().required(
      'Will mechanical protection such as wheel stops and Bollards be required at the Site is required',
    ),
    ClientCompany: Yup.string().required('Client is required'),
    ClientEmail: Yup.string().required('Client Main Contact Email is required'),
    ClientMobileNumber: Yup.string().required('Client Main Contact Mobile Number is required'),
    EVChargerCount: Yup.string().required('How many EVs need to be charged is required'),
    EVChargerInterest: Yup.string().required(
      'How many EV chargers are you interested in is required',
    ),
    EVChargerParkingBay: Yup.string().required(
      'Have you identified the proposed EV charging car parking bays at the Site is required',
    ),
    EVChargerPreferred: Yup.string().required(
      "For AC chargers, do you prefer a 'Socket' type (use your own cable) or a 'Tethered' type (cable attached)?",
    ),
    EVChargerPublicPrivate: Yup.string().required(
      'Will the EV chargers at the proposed site be for private usage only, available to the public or both is required',
    ),
    EVChargerQuickness: Yup.string().required(
      'How quickly will your EVs need to be charged at the Site is required',
    ),
    EVElectrician: Yup.string().required(
      'Do you have a principle contractor or on-site electrician that can/will look after the upstream electrical is required',
    ),
    ImportantNotes: Yup.string().required('Important notes is required'),
    LineMarking: Yup.string().required(
      'Will signage or car park painting (line marking) be required for this EV charging system is required?',
    ),
    MainContact: Yup.string().required('Client Main Contact is required'),
    MainContactRole: Yup.string().required('Client Main Contact Role is required'),
    OtherSiteInformation: Yup.string().required(
      'Are there any other requirements or information about this Site that you would like to share is required',
    ),
    PropertyOwner: Yup.string().required(
      'Do you own the site or is it managed by an outside landlord or property management group is required',
    ),
    ReportingBillingPlatform: Yup.string().required(
      'Are you interested in a reporting and/or billing platform for your EV chargers, similar to Chargefox, to manage usage and payments? is required',
    ),
    SpatialRequirements: Yup.string().required(
      'Are there areas near the proposed EV charger locations that require special consideration due to sensitive equipment is required',
    ),
    SiteName: Yup.string().required('Site Name is required'),
  });

  const multiSiteDraftValidationSchema = Yup.object({
    ClientCompany: Yup.string().required('Client is required'),
  });

  const specialCaseValidationSchema = Yup.object({
    ClientCompany: Yup.string().required('Client is required'),
    ClientEmail: Yup.string().required('Client Main Contact Email is required'),
    ClientMobileNumber: Yup.string().required('Client Main Contact Mobile Number is required'),
    ImportantNotes: Yup.string().required('Important notes is required'),
    MainContact: Yup.string().required('Client Main Contact is required'),
    MainContactRole: Yup.string().required('Client Main Contact Role is required'),
    SiteName: Yup.string().required('Site Name is required'),
  });

  const multiSiteNotDraftValidationSchema = Yup.object({
    // ABNNumber: Yup.string().required('ABN Number is required'),
    ClientCompany: Yup.string().required('Client is required'),
    ClientEmail: Yup.string().required('Client Main Contact Email is required'),
    ClientMobileNumber: Yup.string().required('Client Main Contact Mobile Number is required'),
    ImportantNotes: Yup.string().required('Important notes is required'),
    MainContact: Yup.string().required('Client Main Contact is required'),
    MainContactRole: Yup.string().required('Client Main Contact Role is required'),
  });

  const urlSplit = window.location.href.split('/');
  const id = decodeURIComponent(window.location.href.split('/')[urlSplit.length - 2]);

  const loggedInUser = JSON.parse(localStorage.getItem('JETChargeCurrentUser'));
  const [contractorMode] = useState(window.location.pathname.includes('/contractors/'));
  const [customerMode] = useState(window.location.pathname.includes('/customers/'));
  const [validationSchema, setValidationSchema] = useState(notDraftValidationSchema);
  const [prevValidationSchema, setPrevValidationSchema] = useState(notDraftValidationSchema);
  const [buttonClicked, setSaveOrSubmitButtonClicked] = useState(0);
  const [prevButtonClicked, setPrevButtonClicked] = useState(0);
  const [readOnly] = useState(window.location.href.includes('view'));
  const [siteContactDetails, setSiteContactDetails] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [multiSiteAssessment, setMultiSiteAssessment] = useState(null);
  const [mainSiteContact, setMainSiteContact] = useState({});
  const [mainContact, setMainContact] = useState({});
  const [iswFormRefreshkey, setIswFormRefreshKey] = useState(generateRandomString());
  const [siteContactRefreshKey, setMainContactRefreshKey] = useState(generateRandomString());
  const [contactRefreshKey, setContactRefreshKey] = useState(generateRandomString());
  const [specialCase, setSpecialCase] = useState(props.data?.SpecialCase ?? null);
  const [originalStatus, setOriginalStatus] = useState(props.data?.Status ?? null);
  const [originalSubStatus, setOriginalSubStatus] = useState(props.data?.SubStatus ?? null);
  const [newStatus, setNewStatus] = useState(props.data?.Status ?? null);
  const [newSubStatus, setNewSubStatus] = useState(props.data?.SubStatus ?? null);
  const [tender, setTender] = useState(props.data?.Tender ?? null);
  const [tenderDetails, setTenderDetails] = useState(props.data?.TenderDetails);
  const [tenderDocumentationFiles, setTenderDocumentationFiles] = useState(
    props.data?.TenderDocumentationFiles,
  );
  const [successModalBody, setSuccessModalBody] = useState(
    'Indicative scope of works is successfully saved',
  );
  const [initialValues, setInitialValues] = useState({
    // eslint-disable-next-line
    _4GCommunications: props?.data?._4GCommunications ?? '',
    ABNNumber: props?.data?.ABNNumber ?? '',
    AdditionalStations: props?.data?.AdditionalStations ?? '',
    Bollards: props?.data?.Bollards ?? '',
    ClientCompany: props?.data?.ClientCompany ?? '',
    ClientEmail: props?.data?.ClientEmail ?? '',
    ClientMobileNumber: props?.data?.ClientMobileNumber ?? '',
    ClientMobileNumberCountry: props?.data?.ClientMobileNumberCountry ?? '+61',
    ClientSiteAddress: props?.data?.SiteAddress ?? '',
    ContactName: props?.data?.ContactName ?? '',
    CreatedBy: props?.data?.CreatedBy ?? '',
    DocumentNumber: props?.data?.DocumentNumber ?? '',
    EVChargerCount: props?.data?.EVChargerCount ?? '',
    EVChargerInterest: props?.data?.EVChargerInterest ?? '',
    EVChargerParkingBay: props?.data?.EVChargerParkingBay ?? '',
    EVChargerPreferred: props?.data?.EVChargerPreferred ?? '',
    EVChargerPublicPrivate: props?.data?.EVChargerPublicPrivate ?? '',
    EVChargerQuickness: props?.data?.EVChargerQuickness ?? '',
    EVElectrician: props?.data?.EVElectrician ?? '',
    ID: props?.data?.ID ?? '',
    ImportantNotes: props?.data?.ImportantNotes ?? '',
    LineMarking: props?.data?.LineMarking ?? '',
    MainContact: props?.data?.MainContact ?? '',
    MainContactRole: props?.data?.MainContactRole ?? '',
    OtherSiteInformation: props?.data?.OtherSiteInformation ?? '',
    PropertyOwner: props?.data?.PropertyOwner ?? '',
    ReportingBillingPlatform: props?.data?.ReportingBillingPlatform ?? '',
    SameAsClientDetails: props?.data?.SameAsClientDetails ?? false,
    State: props?.data?.State ?? '',
    SiteAddress: props?.data?.SiteAddress ?? '',
    // SiteContactRole: props?.data?.SiteContactRole ?? '',
    // SiteEmail: props?.data?.SiteEmail ?? '',
    SiteName: props?.data?.SiteName ?? '',
    // SiteMobileNumber: props?.data?.SiteMobileNumber ?? '',
    // SiteMobileNumberCountry: props?.data?.SiteMobileNumberCountry ?? '+61',
    SpatialRequirements: props?.data?.SpatialRequirements ?? '',
    SpecialCase: props?.data?.SpecialCase ?? false,
    Tender: props?.data?.Tender ?? false,
    Status: props?.data?.Status,
    SubStatus: props?.data?.SubStatus,
  });

  const formikRef = useRef();
  const processingModalRef = useRef();
  const successModalRef = useRef();
  const errorModalRef = useRef();
  const requiredFieldsModalRef = useRef();
  const multiSiteModalRef = useRef();
  const siteContactDetails1Ref = useRef();
  const contactDetails1Ref = useRef();
  const privacyPolicyRef = useRef();
  const specialCaseReasonModalRef = useRef();
  const requiredFieldsWarningModalRef = useRef();
  const tenderModalRef = useRef();
  const tenderDetailsRef = useRef();
  const tenderDocumentationRef = useRef();
  const tenderMilestonesRef = useRef();

  const ISW_URL = `${process.env.REACT_APP_TSA_URL}/isw`;
  const MULTI_ISW_URL = `${process.env.REACT_APP_TSA_URL}/isw/multi`;

  const removeSiteContactDetails = (index) => {
    const scdRefList = UtilityClass.siteContactDetailsRefList;
    const scdList = UtilityClass.siteContactDetails;

    const refIndex = scdList.findIndex((item) => item.key === index);
    scdRefList[refIndex].current.hideEverything();

    const tempList = [];
    const tempRefList = [];
    const reIndexedSiteContactDetails = formikRef.current.values?.ContactDetails
      ? [JSON.parse(formikRef.current.values.ContactDetails)[0]]
      : [];

    if (refIndex === scdList.length - 1) {
      UtilityClass.siteContactDetails.pop();
      UtilityClass.siteContactDetailsRefList.pop();

      reIndexedSiteContactDetails.push(
        ...UtilityClass.siteContactDetailsRefList.map((a) => a.current.getInputValues()),
      );

      formikRef.current.values.ContactDetails = JSON.stringify(reIndexedSiteContactDetails);
      setSiteContactDetails(UtilityClass.siteContactDetails);
      return;
    }

    for (let i = 0; i < scdRefList.length; i++) {
      const thisRef = scdRefList[i]?.current;

      const oldValues = scdRefList[i]?.current?.getInputValues();
      if (thisRef?.isVisible()) {
        const currentIndex = Object.keys(oldValues)[0].substring(
          Object.keys(oldValues)[0].indexOf('_') + 1,
        );
        const newIndex = currentIndex - 1;

        // if this is the first item no need to re-index
        if (i < refIndex) {
          tempList.push(UtilityClass.siteContactDetails[i]);
          tempRefList.push(UtilityClass.siteContactDetailsRefList[i]);
        } else {
          const newObject = {};
          Object.keys(oldValues).forEach((key) => {
            newObject[key.replace(`_${currentIndex}`, `_${newIndex}`)] = oldValues[key];
          });

          const tempRef = React.createRef();
          tempRefList.push(tempRef);

          // Recreate the site contact details
          const randomKey = generateRandomString();
          tempList.push(
            <SiteContactDetails
              id={`siteContactDetails_${randomKey}`}
              key={`siteContactDetails_${randomKey}`}
              values={newObject}
              ref={tempRef}
              index={tempList.length + 2}
              showRemoveButton={!contractorMode && !customerMode}
              removeButtonCallback={() => {
                removeSiteContactDetails(`siteContactDetails_${randomKey}`);
              }}
              showSameAsClient
            />,
          );
          reIndexedSiteContactDetails.push(newObject);
        }
      }
    }

    UtilityClass.siteContactDetailsRefList = tempRefList;
    UtilityClass.siteContactDetails = tempList;
    formikRef.current.values.ContactDetails = JSON.stringify(reIndexedSiteContactDetails);

    setSiteContactDetails(tempList);
  };

  const removeContactDetails = (index) => {
    const scdRefList = UtilityClass.contactDetailsRefList;
    const scdList = UtilityClass.contactDetails;

    const refIndex = scdList.findIndex((item) => item.key === index);
    scdRefList[refIndex].current.hideEverything();

    const tempList = [];
    const tempRefList = [];
    const reIndexedContactDetails = formikRef.current.values?.ContactDetails
      ? [JSON.parse(formikRef.current.values.ContactDetails)[0]]
      : [];

    if (refIndex === scdList.length - 1) {
      UtilityClass.contactDetails.pop();
      UtilityClass.contactDetailsRefList.pop();

      reIndexedContactDetails.push(
        ...UtilityClass.contactDetailsRefList.map((a) => a.current.getInputValues()),
      );

      formikRef.current.values.ContactDetails = JSON.stringify(reIndexedContactDetails);
      setContactDetails(UtilityClass.contactDetails);
      return;
    }

    for (let i = 0; i < scdRefList.length; i++) {
      const thisRef = scdRefList[i]?.current;

      const oldValues = scdRefList[i]?.current?.getInputValues();
      if (thisRef?.isVisible()) {
        const currentIndex = Object.keys(oldValues)[0].substring(
          Object.keys(oldValues)[0].indexOf('_') + 1,
        );
        const newIndex = currentIndex - 1;

        // if this is the first item no need to re-index
        if (i < refIndex) {
          tempList.push(UtilityClass.contactDetails[i]);
          tempRefList.push(UtilityClass.contactDetailsRefList[i]);
        } else {
          const newObject = {};
          Object.keys(oldValues).forEach((key) => {
            newObject[key.replace(`_${currentIndex}`, `_${newIndex}`)] = oldValues[key];
          });

          const tempRef = React.createRef();
          tempRefList.push(tempRef);

          // Recreate the site contact details
          const randomKey = generateRandomString();
          tempList.push(
            <ContactDetails
              id={`contactDetails_${randomKey}`}
              key={`contactDetails_${randomKey}`}
              values={newObject}
              ref={tempRef}
              index={tempList.length + 2}
              showLabelTitle={tender || initialValues.Tender ? 'Tender Contact Details' : ''}
              showRemoveButton={!contractorMode && !customerMode}
              removeButtonCallback={() => {
                removeContactDetails(`contactDetails_${randomKey}`);
              }}
              showSameAsClient={!tender}
            />,
          );
          reIndexedContactDetails.push(newObject);
        }
      }
    }

    UtilityClass.contactDetailsRefList = tempRefList;
    UtilityClass.contactDetails = tempList;
    formikRef.current.values.ContactDetails = JSON.stringify(reIndexedContactDetails);

    setContactDetails(tempList);
  };

  const generateSiteContactDetails = (contact) => {
    const scdRefList = UtilityClass.siteContactDetailsRefList;
    const scdList = UtilityClass.siteContactDetails;

    const temp = scdList ? [...scdList] : [];
    const tempRef = React.createRef();

    const tempRefList = scdRefList ? [...scdRefList] : [];
    tempRefList.push(tempRef);

    const randomKey = generateRandomString();
    temp.push(
      <SiteContactDetails
        id={`siteContactDetails_${randomKey}`}
        key={`siteContactDetails_${randomKey}`}
        ref={tempRef}
        values={contact}
        index={temp.length + 2}
        multiSite={multiSiteAssessment}
        showRemoveButton={
          !contractorMode &&
          !customerMode &&
          (!initialValues.SubStatus || initialValues.SubStatus === 'Draft')
        }
        removeButtonCallback={() => {
          removeSiteContactDetails(`siteContactDetails_${randomKey}`);
        }}
        showSameAsClient
      />,
    );

    UtilityClass.siteContactDetailsRefList = tempRefList;
    UtilityClass.siteContactDetails = temp;

    // setSiteContactDetailsRefList(tempRefList)
    setSiteContactDetails(temp);
  };

  const generateContactDetails = (contact) => {
    const scdRefList = UtilityClass.contactDetailsRefList;
    const scdList = UtilityClass.contactDetails;

    const temp = scdList ? [...scdList] : [];
    const tempRef = React.createRef();

    const tempRefList = scdRefList ? [...scdRefList] : [];
    tempRefList.push(tempRef);

    const randomKey = generateRandomString();
    temp.push(
      <ContactDetails
        id={`contactDetails_${randomKey}`}
        key={`contactDetails_${randomKey}`}
        ref={tempRef}
        values={contact}
        index={temp.length + 2}
        showLabelTitle={tender || initialValues.Tender ? 'Tender Contact Details' : ''}
        showRemoveButton={
          !contractorMode &&
          !customerMode &&
          (!initialValues.SubStatus || initialValues.SubStatus === 'Draft')
        }
        removeButtonCallback={() => {
          removeContactDetails(`contactDetails_${randomKey}`);
        }}
        showSameAsClient={!tender}
      />,
    );

    UtilityClass.contactDetailsRefList = tempRefList;
    UtilityClass.contactDetails = temp;

    // setSiteContactDetailsRefList(tempRefList)
    setContactDetails(temp);
  };

  const getExistingIsw = async () => {
    let response = {};
    if (props?.data && props?.data) {
      response = props?.data;
    }

    let tenderResponse = {};
    if (props?.data && props?.data.TenderDetails) {
      tenderResponse = props?.data.TenderDetails;
    }

    let tenderDocumentationFileResponse = [];
    if (props?.data && props?.data.TenderDocumentationFiles) {
      tenderDocumentationFileResponse = props?.data.TenderDocumentationFiles;
    }

    if (!props.childComponent && Number.isInteger(Number(id))) {
      processingModalRef.current.toggleModal();

      const temp = await getAsync(`${ISW_URL}/${id}`);

      response = temp.data ?? {};
      tenderResponse = temp.data.TenderDetails ?? {};
      tenderDocumentationFileResponse = temp.data.TenderDocumentationFiles ?? [];

      setInitialValues(response ?? {});
      setSpecialCase(response.SpecialCase ?? false);
      setTender(response.Tender ?? false);
      setIswFormRefreshKey(generateRandomString());

      processingModalRef.current.toggleModal();
    } else {
      setInitialValues(response ?? {});
      setMainContactRefreshKey(generateRandomString());
      setContactRefreshKey(generateRandomString());

      if (props?.data) {
        setSpecialCase(response?.SpecialCase ?? false);
        setTender(response?.Tender ?? false);
        setIswFormRefreshKey(generateRandomString());
      }
    }

    const siteContacts = JSON.parse(
      response?.SiteContactDetails ? response?.SiteContactDetails : '[]',
    );
    setMainSiteContact({
      SiteContactName_1: siteContacts[0]?.SiteContactName_1 ?? '',
      SiteContactRole_1: siteContacts[0]?.SiteContactRole_1 ?? '',
      SiteMobileNumberCountry_1: siteContacts[0]?.SiteMobileNumberCountry_1 ?? '',
      SiteMobileNumber_1: siteContacts[0]?.SiteMobileNumber_1 ?? '',
      SiteEmail_1: siteContacts[0]?.SiteEmail_1 ?? '',
      SiteAddress_1: siteContacts[0]?.SiteAddress_1 ?? '',
      SiteName_1: siteContacts[0]?.SiteName_1 ?? '',
      State_1: siteContacts[0]?.State_1 ?? '',
    });

    const contacts = JSON.parse(response.ContactDetails ?? '[]');
    setMainContact({
      Name_1: contacts[0]?.Name_1 ?? '',
      Role_1: contacts[0]?.Role_1 ?? '',
      MobileNumberCountry_1: contacts[0]?.MobileNumberCountry_1 ?? '',
      MobileNumber_1: contacts[0]?.MobileNumber_1 ?? '',
      Email_1: contacts[0]?.Email_1 ?? '',
    });

    setTenderDetails(tenderResponse);
    setTenderDocumentationFiles(tenderDocumentationFileResponse);

    // Populate the additional contacts
    if (response.ContactDetails?.length > 0) {
      for (let i = 1; i < contacts.length; i++) {
        generateContactDetails(contacts[i]);
      }
    }
  };

  const saveSingleSiteAssessment = async (body) => {
    let response = {};
    if (initialValues.ID) {
      response = await putAsync(`${ISW_URL}/${initialValues.ID}`, body);
    } else {
      response = await postAsync(`${ISW_URL}`, body);
    }

    return response;
  };

  const saveMultiSiteAssessment = async (body) => {
    const response = await postAsync(`${MULTI_ISW_URL}`, body);
    return response;
  };

  const setCommonFormikValues = async (_status, _substatus, formik) => {
    if (!formik) formik = {};

    if (!formik?.CreatedBy && !formikRef.current.values.CreatedBy) {
      formik.CreatedBy = loggedInUser?.name;
      formik.CreatedByEmail = loggedInUser?.username;
    }

    formik.UpdatedBy = loggedInUser?.name;

    if (formik && Object.keys(formik).length > 0) {
      Object.keys(formik).forEach((key) => {
        formikRef.current.values[key] = formik[key];
      });
    }

    formikRef.current.values.Status = _status;
    formikRef.current.values.SubStatus = _substatus;

    formikRef.current.values.Tender = tender;
    formikRef.current.values.SpecialCase = specialCase;
  };

  const validateSiteContactDetails = async () => {
    let mergedValidationErrors = [];

    const siteContactValidationErrors = await siteContactDetails1Ref.current.validateForm();
    const twoSiteValidationError = {};

    const refListCount = UtilityClass.siteContactDetailsRefList?.length ?? 0;
    if (refListCount === 0 && multiSiteAssessment) {
      twoSiteValidationError.AtLeastTwoSites = 'At least two sites are required';
    }

    const validationPromises = UtilityClass.siteContactDetailsRefList?.map((sc) =>
      sc.current.validateForm(),
    );
    const validationResults = validationPromises ? await Promise.all(validationPromises) : [];
    const additionalSiteContactValidationErrors = validationResults.flatMap(Object.values);

    mergedValidationErrors = [
      ...mergedValidationErrors,
      ...Object.values(twoSiteValidationError),
      ...Object.values(siteContactValidationErrors),
      ...additionalSiteContactValidationErrors,
    ];

    return mergedValidationErrors;
  };

  const validate = async () => {
    // const iswValidationErrors = await formikRef.current.validateForm();
    // let mergedValidationErrors = [...Object.values(iswValidationErrors), ...siteValidationErrors];
    let mergedValidationErrors = [];
    const siteValidationErrors = await validateSiteContactDetails();

    if (tender) {
      const tenderDetailsValidationErrors = await tenderDetailsRef.current.validateForm();
      mergedValidationErrors = [
        ...Object.values(tenderDetailsValidationErrors),
        ...siteValidationErrors,
      ];
    } else {
      const iswValidationErrors = await formikRef.current.validateForm();
      mergedValidationErrors = [...Object.values(iswValidationErrors), ...siteValidationErrors];
    }

    if (newSubStatus === 'Draft' || !newSubStatus) {
      if (mergedValidationErrors.length > 0) {
        formikRef.current.values.Status = originalStatus;
        formikRef.current.values.SubStatus = originalSubStatus;
      }
    } else {
      const defaultContactValidationErrors = await contactDetails1Ref.current.validateForm();
      const fulfilledValidationPromises = [];

      // Initialize an array to hold the promises
      const validationPromises = [];
      for (let i = 0; i < UtilityClass.contactDetailsRefList?.length; i++) {
        // Push the promise returned by validateForm() into the array without waiting here
        validationPromises.push(UtilityClass.contactDetailsRefList[i].current.validateForm());
      }

      fulfilledValidationPromises.push(...(await Promise.all(validationPromises)));
      const additionalContactValidationErrors = fulfilledValidationPromises.flatMap((obj) =>
        Object.values(obj).filter((value) => value !== undefined && value !== null && value !== ''),
      );

      // Manual check for Client Site Address
      if (
        newSubStatus !== 'Draft' &&
        !multiSiteAssessment &&
        !tender &&
        (!document.getElementById('ClientSiteAddress')?.value || !formikRef.current.values.State)
      ) {
        defaultContactValidationErrors.ClientSiteAddress =
          'Client Site Address is required. Please select the address from the dropdown';
        formikRef.current.setFieldError(
          'ClientSiteAddress',
          'Client Site Address is required. Please select the address from the dropdown',
        );
      } else {
        delete defaultContactValidationErrors.ClientSiteAddress;
        formikRef.current.setFieldError('ClientSiteAddress', null);
      }

      mergedValidationErrors = [
        ...mergedValidationErrors,
        ...Object.values(defaultContactValidationErrors),
        ...additionalContactValidationErrors,
      ];

      if (mergedValidationErrors.length > 0) {
        // return formik status to original
        formikRef.current.values.Status = originalStatus;
        formikRef.current.values.SubStatus = originalSubStatus;
      }
    }

    return mergedValidationErrors;
  };

  const SaveOnly = async (statusOverride, subStatusOverride, formikOverride) => {
    let response = {};

    // get the contacts and convert to json string
    formikRef.current.values.IswPdf = await htmlToPdf('iswForm');

    if (statusOverride || subStatusOverride || formikOverride) {
      setCommonFormikValues(statusOverride, subStatusOverride, {
        ...formikRef.current.values,
        ...formikOverride,
      });
    } else {
      setCommonFormikValues(newStatus, newSubStatus, formikRef.current.values);
    }

    const finalMergedContactList = [contactDetails1Ref.current.getInputValues()];
    const finalMergedSiteContactList = [siteContactDetails1Ref.current.getInputValues()];

    // finalMergedContactList.push(contactDetails1Ref.current.getInputValues());
    for (let i = 0; i < UtilityClass?.contactDetailsRefList?.length; i++) {
      finalMergedContactList.push(UtilityClass.contactDetailsRefList[i].current.getInputValues());
    }
    formikRef.current.values.ContactDetails = JSON.stringify(finalMergedContactList);
    formikRef.current.values.SiteContactDetails = JSON.stringify(finalMergedSiteContactList);

    // Add the tender values
    if (tender) {
      setSuccessModalBody('Tender Form successfully saved!');

      const tenderValues = tenderDetailsRef.current?.getInputValues();
      const tenderDocumentation = tenderDocumentationRef.current?.getInputValues();

      const data = {};
      data.tenderProjectTitle = tenderValues.TenderProjectTitle;
      data.tenderLocation = tenderDocumentation?.TenderLocation ?? '';
      data.tenderNotes = tenderDocumentation?.TenderNotes ?? '';

      formikRef.current.values.TenderDetails = {
        ...formikRef.current.values.TenderDetails,
        ...data,
      };
    }

    if (multiSiteAssessment) {
      // finalMergedSiteContactList.push(siteContactDetails1Ref.current.getInputValues());
      for (let i = 0; i < UtilityClass?.siteContactDetailsRefList?.length; i++) {
        finalMergedSiteContactList.push(
          UtilityClass.siteContactDetailsRefList[i].current.getInputValues(),
        );
      }

      // Add the additional contacts
      formikRef.current.values.SiteContactDetails = JSON.stringify(finalMergedSiteContactList);

      const multiResponse = await saveMultiSiteAssessment(formikRef.current.values);
      if (multiResponse.status !== 200) {
        formikRef.current.values.Status = originalStatus;
        formikRef.current.values.SubStatus = originalSubStatus;

        // 1.) Hides the modal
        processingModalRef.current.toggleModal();
        errorModalRef.current.toggleModal();
      } else {
        // 1.) Hides the modal
        processingModalRef.current.toggleModal();
        successModalRef.current.toggleModal();

        setTimeout(() => {
          navigate(`/internal/tsa`);
        }, 3000);
      }
    }

    if (!multiSiteAssessment) {
      // formikRef.current.values.SiteAddress = document.getElementById('ClientSiteAddress')?.value;
      if (!tender) {
        formikRef.current.values.SiteAddress = document.getElementById('ClientSiteAddress')?.value;

        // Since this is not a tender the site address, site name and state are not in the <SiteContactDetails> but instead it resides in this form in Client Details
        const singleSite = JSON.parse(formikRef.current.values.SiteContactDetails)[0];
        singleSite.SiteAddress_1 = formikRef.current.values.SiteAddress;
        singleSite.State_1 = formikRef.current.values.State;
        singleSite.SiteName_1 = formikRef.current.values.SiteName;

        formikRef.current.values.SiteContactDetails = JSON.stringify([singleSite]);
      }

      response = await saveSingleSiteAssessment(formikRef.current.values);
      if (response.status === 200) {
        // Do not redirect if we are in the customers page
        if (window.location.pathname.includes('customers')) {
          return response;
        }

        // 1.) Hides the modal
        processingModalRef.current.toggleModal();
        successModalRef.current.toggleModal();

        const tempFormik = formikRef.current.values;
        setTimeout(() => {
          if (tempFormik.SubStatus === 'Draft') {
            navigate(`/internal/tsa`);
          } else if (tender) {
            navigate(
              `/internal/tender/${response.data.ID ? response.data.ID : response.data[0].ID}/edit`,
            );
          } else {
            navigate(
              `/internal/tsa/${response.data.ID ? response.data.ID : response.data[0].ID}/edit`,
            );
          }
        }, 3000);
      } else {
        formikRef.current.values.Status = originalStatus;
        formikRef.current.values.SubStatus = originalSubStatus;

        // 1.) Hides the modal
        processingModalRef.current.toggleModal();
        errorModalRef.current.toggleModal();
      }
    }

    return response;
  };

  const handleSubmit = async (_status, _subStatus) => {
    // Special Case
    if (
      specialCase &&
      _status === 'Enquiry In' &&
      _subStatus === 'Pending Approval' &&
      !formikRef.current.values.SpecialCaseReason
    ) {
      specialCaseReasonModalRef.current.toggleModal();
      setSuccessModalBody('Approval request is sent');
      return;
    }

    // 1.) Shows the modal
    processingModalRef.current.toggleModal();

    // Hide during saving
    jQuery('.hidePrint').addClass('d-none');

    let schema;
    if (_subStatus === 'Draft') {
      schema = multiSiteAssessment ? multiSiteDraftValidationSchema : draftValidationSchema;
    } else if (specialCase || initialValues.SpecialCase) {
      schema = specialCaseValidationSchema;
    } else {
      schema = multiSiteAssessment ? multiSiteNotDraftValidationSchema : notDraftValidationSchema;
    }

    setValidationSchema(schema);

    setOriginalStatus(formikRef.current.values.Status);
    setOriginalSubStatus(formikRef.current.values.SubStatus);

    setNewStatus(_status);
    setNewSubStatus(_subStatus);

    setSaveOrSubmitButtonClicked(buttonClicked + 1);
  };

  useEffect(() => {
    if (
      Object.keys(prevValidationSchema.fields).length !==
        Object.keys(validationSchema.fields).length ||
      buttonClicked !== prevButtonClicked
    ) {
      setPrevValidationSchema(validationSchema);
      setPrevButtonClicked(buttonClicked);

      const validateAndSave = async () => {
        const validationErrors = await validate();
        if (validationErrors.length === 0) {
          SaveOnly();
        } else {
          // 1.) Hides the modal
          processingModalRef.current.toggleModal();
          requiredFieldsModalRef.current.toggleModal(validationErrors);
        }
      };

      //setTimeout(() => {
      validateAndSave();
      //}, 3000);
    }
  }, [validationSchema, buttonClicked]);

  useEffect(() => {
    if (
      multiSiteAssessment === null &&
      !window.location.href.includes('view') &&
      !window.location.href.includes('edit') &&
      !window.location.href.includes('contractors') &&
      !window.location.href.includes('customers')
    ) {
      // multiSiteModalRef.current.toggleModal();
      tenderModalRef.current.toggleModal();
    }

    // mount
    getExistingIsw();

    // unmount
    return () => {
      UtilityClass.siteContactDetailsRefList = [];
      UtilityClass.siteContactDetails = [];
    };
  }, []);

  useImperativeHandle(ref, () => ({
    async clickSubmitButton(_status, _subStatus, _formik) {
      setMultiSiteAssessment(false);
      const response = await SaveOnly(_status, _subStatus, _formik);

      return response;
    },
    async validateForm() {
      const validationErrors = await validate();
      return validationErrors;
    },
    userAcceptanceCheck() {
      return privacyPolicyRef.current.getCustomerAcceptanceCheck();
    },
  }));

  const handleSiteAssessmentChange = async () => {
    multiSiteModalRef.current.toggleModal();

    if (multiSiteAssessment === null) {
      setMultiSiteAssessment(false);
    }

    if (specialCase === null) {
      setSpecialCase(false);
    }
  };

  const handleClientSiteAddressChange = (place) => {
    formikRef.current.values.State = place?.state;
  };

  return (
    <>
      {!contractorMode && !props.childComponent ? <BreadCrumbs /> : ''}
      <Formik
        key={`siteContactDetails_1${iswFormRefreshkey}`}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={() => {
          // Do nothing. Everything is handled in the handleSubmit function
        }}
      >
        {(formik) => {
          return (
            <>
              <FormikForm id={props.id ?? 'iswForm'}>
                {props.showContainer ? (
                  <>
                    <h2 style={{ textAlign: 'left' }} className="paddingLeftVariable">
                      <strong>Indicative Scope of works / {formik.values.DocumentNumber}</strong>
                    </h2>
                    {customerMode ? <PrivacyPolicy ref={privacyPolicyRef} /> : ''}
                  </>
                ) : (
                  ''
                )}
                {tender || initialValues.Tender ? (
                  ''
                ) : (
                  <Card>
                    <CardTitle
                      tag="h5"
                      className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                    >
                      <span style={{ float: 'left' }}>Client Details</span>
                    </CardTitle>
                    <CardBody className="card-rounded-bottom">
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="ClientCompany">Client (Company, Organisation)</Label>
                            <Field
                              id="ClientCompany"
                              name="ClientCompany"
                              className="form-control"
                              value={formik.values.ClientCompany}
                            />
                            {formik.errors.ClientCompany ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.ClientCompany}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="MainContact">Client Main Contact name</Label>
                            <Field
                              id="MainContact"
                              name="MainContact"
                              className="form-control"
                              value={formik.values.MainContact}
                            />
                            {formik.errors.MainContact ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.MainContact}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="ABNNumber">ABN Number</Label>
                            <Field
                              id="ABNNumber"
                              name="ABNNumber"
                              className="form-control"
                              value={formik.values.ABNNumber}
                            />
                            {formik.errors.ABNNumber ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.ABNNumber}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="MainContactRole">Client Main Contact Role</Label>
                            <Field
                              id="MainContactRole"
                              name="MainContactRole"
                              className="form-control"
                              value={formik.values.MainContactRole}
                            />
                            {formik.errors.MainContactRole ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.MainContactRole}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="ClientEmail">Client Main Contact Email</Label>
                            <Field
                              id="ClientEmail"
                              name="ClientEmail"
                              className="form-control"
                              value={formik.values.ClientEmail}
                            />
                            {formik.errors.ClientEmail ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.ClientEmail}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="ClientMobileNumberCountry">
                              Client Main Contact Mobile Number
                            </Label>
                            <div style={{ display: 'flex' }}>
                              <Field
                                id="ClientMobileNumberCountry"
                                as="select"
                                className="form-select"
                                name="ClientMobileNumberCountry"
                                onChange={formik.handleChange}
                                style={{ width: '100px', marginRight: '10px' }}
                              >
                                <option value="+61">+61</option>
                                <option value="+64">+64</option>
                              </Field>
                              <Field
                                id="ClientMobileNumber"
                                name="ClientMobileNumber"
                                className="form-control"
                                value={formik.values.ClientMobileNumber}
                                style={{ width: '200px', marginRight: 0 }}
                              />
                            </div>
                            {formik.errors.ClientMobileNumber ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.ClientMobileNumber}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      {!multiSiteAssessment ? (
                        <Row>
                          <Col md="6" style={{ textAlign: 'left' }}>
                            <FormGroup>
                              <Label htmlFor="SiteName">Site Name</Label>
                              <Field
                                id="SiteName"
                                name="SiteName"
                                className="form-control"
                                value={formik.values.SiteName}
                              />
                              {formik.errors.SiteName && (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.SiteName}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6" style={{ textAlign: 'left' }}>
                            <FormGroup>
                              <Label htmlFor="ClientSiteAddress">Site Address</Label>
                              <GooglePlacesAutocomplete
                                id="ClientSiteAddress"
                                name="ClientSiteAddress"
                                initialValue={formik.values.SiteAddress}
                                initialStateValue={formik.values.State}
                                callBack={(e) => {
                                  handleClientSiteAddressChange(e);
                                }}
                              />
                              {formik.errors.ClientSiteAddress ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.ClientSiteAddress}
                                </div>
                              ) : (
                                ''
                              )}
                              <Field
                                name="ClientSiteAddress"
                                className="form-control d-none"
                                value={formik.values.ClientSiteAddress}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                    </CardBody>
                  </Card>
                )}
                {tender || initialValues.Tender ? (
                  <TenderDetails data={tenderDetails} ref={tenderDetailsRef} />
                ) : (
                  ''
                )}
                {
                  <ContactDetails
                    id="contactDetails_1"
                    key={contactRefreshKey}
                    ref={contactDetails1Ref}
                    showLabelTitle={tender || initialValues.Tender ? 'Tender Contact Details' : ''}
                    showAddContactButton={
                      !readOnly &&
                      !contractorMode &&
                      !customerMode &&
                      (!formikRef?.current?.values?.SubStatus ||
                        formikRef?.current?.values?.SubStatus === 'Draft')
                    }
                    addContactCallback={generateContactDetails}
                    index={1}
                    values={mainContact}
                    showSameAsClient={!tender}
                  />
                }
                {contactDetails}
                {
                  <SiteContactDetails
                    id="siteContactDetails_1"
                    key={siteContactRefreshKey}
                    ref={siteContactDetails1Ref}
                    multiSite={multiSiteAssessment || tender}
                    showAddSiteButton={
                      !readOnly &&
                      !contractorMode &&
                      !customerMode &&
                      multiSiteAssessment &&
                      (!formikRef?.current?.values?.SubStatus ||
                        formikRef?.current?.values?.SubStatus === 'Draft')
                    }
                    addSiteCallback={generateSiteContactDetails}
                    index={1}
                    values={mainSiteContact}
                    showSameAsClient
                  />
                }
                {siteContactDetails}
                {customerMode || contractorMode || tender || initialValues.Tender ? (
                  ''
                ) : (
                  <Card>
                    <CardTitle
                      tag="h5"
                      className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                    >
                      <span style={{ float: 'left' }}>Important Notes - internal facing</span>
                      <Icon.HelpCircle
                        id="importantNotesTooltipIcon"
                        size={20}
                        style={{ color: 'blue', float: 'left', marginLeft: '8px' }}
                      />
                      <TooltipItem target="importantNotesTooltipIcon" placement="left">
                        <p style={{ textAlign: 'left' }}>
                          Please add information on whether JET Charge quoted or assessed this site
                          or conducted works on this site in the past. If yes, add a link to the
                          folder location and quote details.
                        </p>
                      </TooltipItem>
                    </CardTitle>
                    <CardBody className="card-rounded-bottom">
                      <FormGroup>
                        <Row>
                          <Col md="12">
                            <Field
                              className="form-control"
                              component="textarea"
                              name="ImportantNotes"
                              placeholder=""
                              // defaultValue={workOrderDetails.DescriptionOfServices}
                              rows="5"
                              style={{ resize: 'none' }}
                            />
                          </Col>
                          <div
                            style={{ display: 'block', textAlign: 'left' }}
                            className="invalid-feedback"
                          >
                            {formik.errors.ImportantNotes}
                          </div>
                        </Row>
                      </FormGroup>
                    </CardBody>
                  </Card>
                )}

                {(tender || initialValues.Tender) && Number.isInteger(Number(id)) ? (
                  <Milestone
                    ref={tenderMilestonesRef}
                    warningMessage={
                      !Number.isInteger(Number(id))
                        ? '- Please Save as Draft first before you can add documents'
                        : ''
                    }
                    milestoneUrl={`${process.env.REACT_APP_TSA_URL}/tender/milestone/${id}`}
                    data={tenderDetails?.milestones ?? []}
                    showAddButton={Number.isInteger(Number(id))}
                    parentId={id}
                  />
                ) : (
                  ''
                )}
                {(tender || initialValues.Tender) && Number.isInteger(Number(id)) ? (
                  <TenderDocumentation
                    title="Tender Documentation"
                    warningMessage={
                      !Number.isInteger(Number(id))
                        ? '- Please Save as Draft first before you can add documents'
                        : ''
                    }
                    fileUploadUrl={`${process.env.REACT_APP_TSA_URL}/tender/${id}/file`}
                    fileDownloadUrl={`${process.env.REACT_APP_TSA_URL}/tender/${id}/file`}
                    disabled={!Number.isInteger(Number(id))}
                    existingFiles={tenderDocumentationFiles}
                    existingNotes={tenderDetails?.tenderNotes}
                    existingTenderLocation={tenderDetails?.tenderLocation}
                    ref={tenderDocumentationRef}
                  />
                ) : (
                  ''
                )}
                {!multiSiteAssessment &&
                !(initialValues.SpecialCase || specialCase) &&
                !(initialValues.Tender || tender) ? (
                  <Card>
                    <CardTitle
                      tag="h5"
                      className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                    >
                      <span style={{ float: 'left' }}>Site Requirements</span>
                    </CardTitle>
                    <CardBody className="card-rounded-bottom">
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="EVChargerCount">
                              1.) How many EVs need to be charged?
                            </Label>
                            <Field
                              name="EVChargerCount"
                              className="form-control"
                              value={formik.values.EVChargerCount}
                              maxLength="100"
                            />
                            {formik.errors.EVChargerCount ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.EVChargerCount}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="EVChargerInterest">
                              2.) How many EV chargers are you interested in?
                            </Label>
                            <Field
                              name="EVChargerInterest"
                              className="form-control"
                              value={formik.values.EVChargerInterest}
                              maxLength="100"
                            />
                            {formik.errors.EVChargerInterest ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.EVChargerInterest}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="EVChargerQuickness">
                              3.) How quickly will your EVs need to be charged at the Site?
                            </Label>
                            <br />
                            <a
                              href="https://jetcharge.com.au/resource/how-long-to-charge-my-car/"
                              style={{ color: 'blue' }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              * Difference between AC vs DC charging
                            </a>
                            <Field
                              className="form-control"
                              component="textarea"
                              name="EVChargerQuickness"
                              placeholder=""
                              value={formik.values.EVChargerQuickness}
                              rows="3"
                              style={{ resize: 'none' }}
                            />
                            {formik.errors.EVChargerQuickness ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.EVChargerQuickness}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" style={{ textAlign: 'left' }}>
                          <Label htmlFor="EVChargerPublicPrivate">
                            4.) Will the EV chargers at the proposed site be for private usage only,
                            available to the public or both?
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="private"
                                name="EVChargerPublicPrivate"
                                checked={formik.values.EVChargerPublicPrivate === 'private'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Private</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="public"
                                name="EVChargerPublicPrivate"
                                checked={formik.values.EVChargerPublicPrivate === 'public'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Public facing</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="both"
                                name="EVChargerPublicPrivate"
                                checked={formik.values.EVChargerPublicPrivate === 'both'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Both</Label>
                            </FormGroup>
                            {formik.errors.EVChargerPublicPrivate ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.EVChargerPublicPrivate}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="EVElectrician">
                            5.) Do you have a principle contractor or on-site electrician that
                            can/will look after the upstream electrical?
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="yes"
                                name="EVElectrician"
                                checked={formik.values.EVElectrician === 'yes'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Yes</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="no"
                                name="EVElectrician"
                                checked={formik.values.EVElectrician === 'no'}
                                onChange={formik.handleChange}
                              />
                              <Label check>No</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="EVElectrician"
                                checked={formik.values.EVElectrician === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {formik.errors.EVElectrician ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.EVElectrician}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="PropertyOwner">
                            6.) Do you own the site or is it managed by an outside landlord or
                            property management group?
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="owned"
                                name="PropertyOwner"
                                checked={formik.values.PropertyOwner === 'owned'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Owned</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="leased"
                                name="PropertyOwner"
                                checked={formik.values.PropertyOwner === 'leased'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Leased</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="PropertyOwner"
                                checked={formik.values.PropertyOwner === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {formik.errors.PropertyOwner ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.PropertyOwner}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="EVChargerParkingBay">
                            7.) Have you identified the proposed EV charging car parking bays at the
                            Site?
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="yes"
                                name="EVChargerParkingBay"
                                checked={formik.values.EVChargerParkingBay === 'yes'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Yes</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="no"
                                name="EVChargerParkingBay"
                                checked={formik.values.EVChargerParkingBay === 'no'}
                                onChange={formik.handleChange}
                              />
                              <Label check>No</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="EVChargerParkingBay"
                                checked={formik.values.EVChargerParkingBay === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {formik.errors.EVChargerParkingBay ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.EVChargerParkingBay}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="SpatialRequirements">
                              8.) Are there areas near the proposed EV charger locations that
                              require special consideration due to sensitive equipment or hazardous
                              materials?
                            </Label>
                            &nbsp;
                            <Icon.HelpCircle
                              id="toolTip-spatialRequirements"
                              size={20}
                              style={{ color: 'blue' }}
                            />
                            <TooltipItem target="toolTip-spatialRequirements" placement="top">
                              <div>
                                <p style={{ textAlign: 'left' }}>
                                  This includes ensuring safe distances from things like medical
                                  machines (e.g., MRI) or fuel storage areas, following electrical
                                  installation standards.
                                </p>
                                <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                  <li>
                                    Yes, there are specific areas to consider, please elaborate
                                    below.
                                  </li>
                                  <li>No, there are no specific concerns.</li>
                                  <li>Unsure – I need guidance on this.</li>
                                </ul>
                                <p style={{ textAlign: 'left' }}>
                                  Note: This helps ensure chargers are placed safely and comply with
                                  regulations.
                                </p>
                              </div>
                            </TooltipItem>
                            <Field
                              className="form-control"
                              component="textarea"
                              name="SpatialRequirements"
                              placeholder=""
                              value={formik.values.SpatialRequirements}
                              rows="3"
                              style={{ resize: 'none' }}
                            />
                          </FormGroup>
                          {formik.errors.SpatialRequirements ? (
                            <div
                              style={{ display: 'block', textAlign: 'left' }}
                              className="invalid-feedback"
                            >
                              {formik.errors.SpatialRequirements}
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="_4GCommunications">
                            9.) Do you have strong 4G network coverage in the proposed location of
                            the chargers?
                          </Label>
                          &nbsp;
                          <Icon.HelpCircle
                            id="toolTip-4GComms"
                            size={20}
                            style={{ color: 'blue' }}
                          />
                          <TooltipItem target="toolTip-4GComms" placement="top">
                            <p style={{ textAlign: 'left' }}>
                              You can confirm coverage strength by checking bars on your mobile
                              phone, 2 or more bars means strong coverage.
                            </p>
                          </TooltipItem>
                          <FormGroup>
                            <FormGroup check inline>
                              {/* eslint-disable-next-line no-underscore-dangle */}
                              <Input
                                type="radio"
                                value="yes"
                                name="_4GCommunications"
                                checked={formik.values._4GCommunications === 'yes'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Yes</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              {/* eslint-disable-next-line no-underscore-dangle */}
                              <Input
                                type="radio"
                                value="no"
                                name="_4GCommunications"
                                checked={formik.values._4GCommunications === 'no'}
                                onChange={formik.handleChange}
                              />
                              <Label check>No</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              {/* eslint-disable-next-line no-underscore-dangle */}
                              <Input
                                type="radio"
                                value="unsure"
                                name="_4GCommunications"
                                checked={formik.values._4GCommunications === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {/* eslint-disable-next-line no-underscore-dangle */}
                            {formik.errors._4GCommunications ? (
                              //eslint-disable-next-line no-underscore-dangle
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors._4GCommunications}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="EVChargerPreferred">
                            10.) For AC chargers, do you prefer a &apos;Socket&apos; type (use your
                            own cable) or a &apos;Tethered&apos; type (cable attached)?
                          </Label>
                          <Label style={{ display: 'block', color: 'blue' }}>
                            Note: Only for AC chargers. DC chargers always include a built-in cable.
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="type2"
                                name="EVChargerPreferred"
                                checked={formik.values.EVChargerPreferred === 'type2'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Socket type preferred</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="no"
                                name="EVChargerPreferred"
                                checked={formik.values.EVChargerPreferred === 'no'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Type 2 tethered preferred</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="EVChargerPreferred"
                                checked={formik.values.EVChargerPreferred === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {formik.errors.EVChargerPreferred ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.EVChargerPreferred}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="LineMarking">
                            11.) Will signage or car park painting (line marking) be required for
                            this EV charging system?
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="required"
                                name="LineMarking"
                                checked={formik.values.LineMarking === 'required'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Signage required</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="linemarkingrequired"
                                name="LineMarking"
                                checked={formik.values.LineMarking === 'linemarkingrequired'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Line Marking required</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="signagelinemarkingrequired"
                                name="LineMarking"
                                checked={formik.values.LineMarking === 'signagelinemarkingrequired'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Signage & Line marking required</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="notrequired"
                                name="LineMarking"
                                checked={formik.values.LineMarking === 'notrequired'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Not Required</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="LineMarking"
                                checked={formik.values.LineMarking === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {formik.errors.LineMarking ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.LineMarking}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="Bollards">
                            12.) Will mechanical protection such as wheel stops and Bollards be
                            required at the Site?
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="required"
                                name="Bollards"
                                checked={formik.values.Bollards === 'required'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Required</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="notrequired"
                                name="Bollards"
                                checked={formik.values.Bollards === 'notrequired'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Not Required</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="Bollards"
                                checked={formik.values.Bollards === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {formik.errors.Bollards ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.Bollards}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="AdditionalStations">
                            13.) Are you interested in adding capacity for additional future EV
                            charging stations as part of this installation? This assists JET Charge
                            in understanding the potential need for load management system or
                            increased switchboard and electrical cable rated capacity. Note the
                            inclusion of future-capacity will increase project cost.
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="yes"
                                name="AdditionalStations"
                                checked={formik.values.AdditionalStations === 'yes'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Yes</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="no"
                                name="AdditionalStations"
                                checked={formik.values.AdditionalStations === 'no'}
                                onChange={formik.handleChange}
                              />
                              <Label check>No</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="AdditionalStations"
                                checked={formik.values.AdditionalStations === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure</Label>
                            </FormGroup>
                            {formik.errors.AdditionalStations ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.AdditionalStations}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <Label htmlFor="ReportingBillingPlatform">
                            14.) Are you interested in a reporting and/or billing platform for your
                            EV chargers, similar to Chargefox, to manage usage and payments?
                          </Label>
                          <FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="yes"
                                name="ReportingBillingPlatform"
                                checked={formik.values.ReportingBillingPlatform === 'yes'}
                                onChange={formik.handleChange}
                              />
                              <Label check>
                                Yes, I am interested in a reporting and billing platform.
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="no"
                                name="ReportingBillingPlatform"
                                checked={formik.values.ReportingBillingPlatform === 'no'}
                                onChange={formik.handleChange}
                              />
                              <Label check>
                                No, I do not require a reporting and billing platform.
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                value="unsure"
                                name="ReportingBillingPlatform"
                                checked={formik.values.ReportingBillingPlatform === 'unsure'}
                                onChange={formik.handleChange}
                              />
                              <Label check>Unsure, I would like more information about this.</Label>
                            </FormGroup>
                            {formik.errors.ReportingBillingPlatform ? (
                              <div
                                style={{ display: 'block', textAlign: 'left' }}
                                className="invalid-feedback"
                              >
                                {formik.errors.ReportingBillingPlatform}
                              </div>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      &nbsp;
                      <Row>
                        <Col md="12" style={{ textAlign: 'left' }}>
                          <FormGroup>
                            <Label htmlFor="OtherSiteInformation">
                              15.) Are there any other requirements or information about this Site
                              that you would like to share?
                            </Label>
                            <Field
                              className="form-control"
                              component="textarea"
                              name="OtherSiteInformation"
                              placeholder=""
                              value={formik.values.OtherSiteInformation}
                              rows="3"
                              style={{ resize: 'none' }}
                            />
                          </FormGroup>
                          {formik.errors.OtherSiteInformation ? (
                            <div
                              style={{ display: 'block', textAlign: 'left' }}
                              className="invalid-feedback"
                            >
                              {formik.errors.OtherSiteInformation}
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ) : (
                  ''
                )}
                <Row>
                  <Col xs="12" md="6">
                    <ProcessingModal ref={processingModalRef} />
                  </Col>
                  <Col xs="12" md="6">
                    <GenericMessageModal ref={successModalRef} body={successModalBody} />
                  </Col>

                  <Col xs="12" md="6">
                    <ErrorModal ref={errorModalRef} />
                  </Col>
                  <Col>
                    <RequiredFieldsWarningModal ref={requiredFieldsModalRef} />
                  </Col>
                  <Col>
                    <SmallModal
                      ref={multiSiteModalRef}
                      showSubmitButton
                      submitCallBack={handleSiteAssessmentChange}
                    >
                      <FormGroup>
                        <Row>
                          <Col md="12" className="d-flex justify-content-left align-items-left">
                            <Label>Choose an Assessment Type</Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="d-flex justify-content-center align-items-center">
                            <FormGroup check>
                              <Input
                                type="radio"
                                name="singleSite"
                                checked={!multiSiteAssessment}
                                onChange={() => {
                                  setMultiSiteAssessment(false);
                                }}
                              />
                              <Label check>Single-site</Label>
                            </FormGroup>
                          </Col>
                          <Col md="6" className="d-flex justify-content-center align-items-center">
                            <FormGroup check>
                              <Input
                                type="radio"
                                name="multiSite"
                                checked={multiSiteAssessment}
                                onChange={() => {
                                  setMultiSiteAssessment(true);
                                  setSpecialCase(false);
                                }}
                              />
                              <Label check>Multi-site</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        {!multiSiteAssessment && !tender && !initialValues.Tender ? (
                          <>
                            <hr />
                            <Row>
                              <Col md="12" className="d-flex justify-content-left align-items-left">
                                <Label>
                                  Does this Site Assessment fall under special circumstances?
                                </Label>
                                <Icon.HelpCircle
                                  id="specialCircumstancesTooltipIcon"
                                  size={20}
                                  style={{ color: 'blue', float: 'left', marginLeft: '8px' }}
                                />
                                <TooltipItem
                                  target="specialCircumstancesTooltipIcon"
                                  placement="left"
                                >
                                  <>
                                    <p style={{ textAlign: 'left' }}>
                                      Special Circumstances Defined
                                    </p>
                                    <p style={{ textAlign: 'left' }}>
                                      Extended Travel Impact: Will travel to the site require more
                                      than 2 hours from our JET Charge offices, potentially delaying
                                      TSA completion?
                                    </p>
                                    <p style={{ textAlign: 'left' }}>
                                      Competitive Presence: Have competitors already visited the
                                      site, creating a risk of losing the opportunity through their
                                      swift action?
                                    </p>
                                    <p style={{ textAlign: 'left' }}>
                                      Strategic Client Engagement: Is the client a key strategic
                                      opportunity and where asking them to obtain essential
                                      information remotely has proven to be challenging (Proof
                                      required)
                                    </p>
                                  </>
                                </TooltipItem>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                md="6"
                                className="d-flex justify-content-center align-items-center"
                              >
                                <FormGroup check>
                                  <Input
                                    type="radio"
                                    name="specialCase"
                                    checked={specialCase}
                                    onChange={() => {
                                      setSpecialCase(true);
                                      setMultiSiteAssessment(false);
                                    }}
                                  />
                                  <Label check>Yes</Label>
                                </FormGroup>
                              </Col>
                              <Col
                                md="6"
                                className="d-flex justify-content-center align-items-center"
                              >
                                <FormGroup check>
                                  <Input
                                    type="radio"
                                    name="notSpecialCase"
                                    checked={!specialCase}
                                    onChange={() => {
                                      setSpecialCase(false);
                                    }}
                                  />
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ''
                        )}
                      </FormGroup>
                    </SmallModal>
                  </Col>
                  <Col>
                    <SmallModal
                      ref={tenderModalRef}
                      showSubmitButton
                      submitCallBack={() => {
                        tenderModalRef.current.toggleModal();
                        multiSiteModalRef.current.toggleModal();
                      }}
                    >
                      <FormGroup>
                        <Row>
                          <Col md="12" className="d-flex justify-content-left align-items-left">
                            <Label>Is this for a tender?</Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="d-flex justify-content-center align-items-center">
                            <FormGroup check>
                              <Input
                                type="radio"
                                name="tenderYes"
                                checked={tender}
                                onChange={() => {
                                  setTender(true);
                                  setSpecialCase(false);
                                }}
                              />
                              <Label check>Yes</Label>
                            </FormGroup>
                          </Col>
                          <Col md="6" className="d-flex justify-content-center align-items-center">
                            <FormGroup check>
                              <Input
                                type="radio"
                                name="tenderNo"
                                checked={!tender}
                                onChange={() => {
                                  setTender(false);
                                  setSpecialCase(false);
                                }}
                              />
                              <Label check>No</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </SmallModal>
                  </Col>
                  <Col>
                    <ConfirmationModal
                      ref={specialCaseReasonModalRef}
                      showCommentBox
                      submitCallBack={() => {
                        formik.values.SpecialCaseReason =
                          specialCaseReasonModalRef.current.getCommentBody();
                        handleSubmit('Enquiry In', 'Pending Approval');
                      }}
                      body="Please provide a reason for the special case"
                      formikProps={formik}
                    />
                  </Col>

                  <Col>
                    <RequiredFieldsWarningModal ref={requiredFieldsWarningModalRef} />
                  </Col>
                </Row>
              </FormikForm>
              {(!formikRef?.current?.values?.SubStatus ||
                formikRef?.current?.values?.SubStatus === 'Draft') &&
              !readOnly &&
              !props.childComponent ? (
                <>
                  <input
                    type="button"
                    className="btn btn-primary mb-3 text-black"
                    style={{ width: '135px' }}
                    value="Cancel"
                    onClick={() => {
                      window.location.href = '/internal/tsa';
                    }}
                  />
                  &nbsp;&nbsp;
                  <input
                    type="button"
                    className="btn btn-primary mb-3 text-black"
                    style={{ width: '135px' }}
                    value="Save as Draft"
                    onClick={() => {
                      handleSubmit('Enquiry In', 'Draft');
                    }}
                  />
                  &nbsp;&nbsp;
                  {specialCase || tender || (!tender && !multiSiteAssessment) ? (
                    <input
                      type="button"
                      className="btn btn-primary mb-3 text-black"
                      style={{ width: '135px' }}
                      value={`${tender ? 'Next' : 'Complete'} `}
                      onClick={() => {
                        if (specialCase) handleSubmit('Enquiry In', 'Pending Approval');
                        else if (tender) handleSubmit('Enquiry In', 'Documents Uploaded');
                        else handleSubmit('Enquiry In', 'Questionnaire Completed');
                      }}
                    />
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
            </>
          );
        }}
      </Formik>
    </>
    // </Container >
  );
});

IswForm.propTypes = {
  childComponent: PropTypes.bool,
  data: PropTypes.object,
  id: PropTypes.string,
  showContainer: PropTypes.bool,
};

export default IswForm;
